import React from "react"
import { Container, Top, Bottom, Title } from "./style"
import { ReadMore } from "../ReadMore"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const RecentArticlesElement = ({
  allArticles,
  article,
  isCaseStudy,
}) => {
  const { title, thumbnail, slug } = article
  return (
    <Container allArticles={allArticles}>
      <Top isCaseStudy={isCaseStudy}>
        {isCaseStudy ? (
          <GatsbyImage
            style={{ height: "100%" }}
            image={getImage(thumbnail)}
            alt="alt"
          />
        ) : (
          <GatsbyImage image={getImage(thumbnail)} alt="alt" />
        )}
      </Top>
      <Bottom>
        <Title>{title}</Title>
        <ReadMore
          desiredPath={isCaseStudy ? `/case-studies/${slug}` : `/blog/${slug}`}
        />
      </Bottom>
    </Container>
  )
}
