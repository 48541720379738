import styled from "styled-components"

export const Container = styled.div`
  margin: 0 auto 100px;
  max-width: calc(1400px + 8vw);
  padding: 0 8vw;
  @media (max-width: 890px) {
    margin: 0 auto 64px;
    padding: 0 8vw;
  }
`

export const Top = styled.div`
  display: grid;
  grid-template-columns: 48% 52%;
  align-items: center;
  @media (max-width: 890px) {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
`

export const Right = styled.div`
  position: relative;
`

export const ImageWrapper = styled.div`
  position: absolute;
  width: 65%;
  top: 18%;
  left: 15%;
`
export const HtmlContainer = styled.div`
  & > h3 {
    margin: 56px 0 20px;
  }

  & > h4 {
    margin: 40px 0 18px;
  }

  & > p {
    margin-bottom: 28px;
  }

  & > ul {
    margin-left: 24px;
    margin-bottom: 24px;
    /* list-style-type: none; */
  }

  & > ul > li {
    margin-bottom: 12px;
  }
  @media (max-width: 890px) {
  }
`
