import styled, { css } from "styled-components"

export const Container = styled.div`
  display: grid;
  grid-template-rows: 65% 35%;
  height: 400px;
  width: 340px;
  margin: 40px 7%;
  ${(props) =>
    props.allArticles &&
    css`
      margin: 0;
    `}
  @media (max-width: 890px) {
    width: initial;
  }
`

export const Top = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) => !props.isCaseStudy && "1px solid #212529"};
  object-fit: cover;
`

export const Bottom = styled.div`
  border: 1px solid #212529;
  border-top: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  background-color: white;
`

export const Title = styled.h3``
