import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Container, Header, ButtonWrapper } from "./style"
import { RecentArticlesElement } from "../RecentArticlesElement"
import nextArrowImg from "../../images/carousel-arrow-next.svg"
import prevArrowImg from "../../images/carousel-arrow-previous.svg"
import { ColoredSpan } from "../ColoredSpan"
import { Button } from "../Button"

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <img {...props} src={nextArrowImg} alt="sth" />
)

const PrevArrow = ({ currentSlide, slideCount, ...props }) => (
  <img {...props} src={prevArrowImg} alt="sth" />
)
export const RecentArticles = ({ home, articles, isCaseStudy }) => {
  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <Container>
      <Header>
        Recent <ColoredSpan color="blue" text="articles" />
      </Header>
      <Slider {...settings}>
        {articles.map((article) => (
          <RecentArticlesElement
            isCaseStudy={isCaseStudy}
            key={article.id}
            article={article}
          />
        ))}
      </Slider>
      {home && (
        <ButtonWrapper>
          <Button to="/blog" white={+true} text="See all articles" />
        </ButtonWrapper>
      )}
    </Container>
  )
}
