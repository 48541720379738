import styled from "styled-components"

export const Container = styled.div`
  margin: 0 auto 100px;
  max-width: calc(1310px + 8vw);
  padding: 0 8vw;
  @media (max-width: 890px) {
    margin: 0 auto 60px;
    padding: 0 8vw;
  }
`

export const Header = styled.h2`
  margin-left: -42px;
  margin-bottom: 20px;
  @media (max-width: 890px) {
    margin: 0;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: -42px;
  @media (max-width: 890px) {
    margin-right: 0;
    justify-content: center;
  }
`
