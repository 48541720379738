import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import { Layout } from "../../components/Layout"
import { graphql } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import { RecentArticles } from "../../components/RecentArticles"
import { Top, HtmlContainer, ImageWrapper, Right, Container } from "./style"

export default function GeneratedPage({ data }) {
  const { title, thumbnail, content } = data.contentfulOakTeamsCaseStudy
  const otherCaseStudies = data.recentCaseStudies.nodes
  const image = getImage(thumbnail)
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => {
        return <p>{children}</p>
      },
    },
  }
  return (
    <Layout>
      <Container>
        <Top>
          <h1>{title}</h1>
          <Right>
            <StaticImage
              src="../../images/case-study-page-shapes.png"
              alt="shapes"
              placeholder="blurred"
              quality="100"
              style={{
                height: "100%",
              }}
            />
            <ImageWrapper>
              <GatsbyImage image={image} alt="alt" />
            </ImageWrapper>
          </Right>
        </Top>
        <HtmlContainer>{renderRichText(content, options)}</HtmlContainer>
      </Container>
      <RecentArticles isCaseStudy={true} articles={otherCaseStudies} />
    </Layout>
  )
}

export const query = graphql`
  query CaseStudy($slug: String) {
    contentfulOakTeamsCaseStudy(slug: { eq: $slug }) {
      title
      thumbnail {
        gatsbyImageData
      }
      content {
        raw
      }
    }
    recentCaseStudies: allContentfulOakTeamsCaseStudy(
      sort: { fields: createdAt, order: DESC }
      limit: 8
    ) {
      nodes {
        id
        title
        thumbnail {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
        slug
      }
    }
  }
`
